/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import { Button, InputSelect, InputTextbox, ModalMd } from 'c2-common-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import IntlFormatterHOC from '../../../utils/intlFormatterHOC';
import telemetryEvent, { Events, Features } from '../../../utils/telemetry';

const AddAutoprovisioningModal = ({ isOpen, onSubmit, onToggleModal, roleToGroupMapping, roles }) => {
  // Component States
  const [hasEmptyRows, setHasEmptyRows] = React.useState(true);
  const [rows, setRows] = React.useState([]);

  const handleAddRow = () => {
    const newRow = {
      group: '',
      role: null,
    };

    setRows(rows.concat([newRow]));

    telemetryEvent()
      .button()
      .click()
      .feature(Features.AUTOPROVISIONING)
      .event(Events.ADD_AUTOPROVISIONING_GROUP_ROLE_INPUT_ROW);
  };

  const handleDeleteRow = index => () => {
    const rowsCopy = [...rows];

    rowsCopy.splice(index, 1);

    setRows(rowsCopy);

    telemetryEvent()
      .button()
      .click()
      .feature(Features.AUTOPROVISIONING)
      .event(Events.DELETE_AUTOPROVISIONING_GROUP_ROLE_INPUT_ROW);
  };

  const handleInputChange = index => event => {
    const rowsCopy = [...rows],
      target = event.currentTarget;

    rowsCopy[index].group = target.value;

    setRows(rowsCopy);
  };

  const handlePostUserInputMetric = () => {
    telemetryEvent()
      .input()
      .typing()
      .feature(Features.AUTOPROVISIONING)
      .event(Events.INPUT_AUTOPROVISIONING_GROUP_NAME);
  };

  const handleSubmit = () => {
    const roleToGroupMapping = {};

    rows
      .filter(({ group, role }) => group !== '' && role !== null)
      .forEach(({ group, role }) => {
        if (roleToGroupMapping[group]) {
          const roleArr = [roleToGroupMapping[group]];

          roleToGroupMapping[group] = roleArr.concat([role.value]);
        } else {
          roleToGroupMapping[group] = role.value;
        }
      });

    handleToggle();
    onSubmit(roleToGroupMapping);
  };

  const handleToggle = () => {
    onToggleModal();
  };

  const handleUpdateSelectedObj = index => selectedObj => {
    const rowsCopy = [...rows];

    rowsCopy.forEach((row, i) => {
      if (index === i) {
        row.role = selectedObj;
      }
    });

    setRows(rowsCopy);

    telemetryEvent().input().click().feature(Features.AUTOPROVISIONING).event(Events.SELECT_AUTOPROVISIONING_ROLE);
  };

  React.useEffect(() => {
    const rows = [];

    if (roleToGroupMapping.length > 0) {
      roleToGroupMapping.forEach(mapping => {
        rows.push({
          group: mapping.group,
          role: roles.filter(role => role.value === mapping.role)[0] || null,
        });
      });
    } else {
      rows.push({
        group: '',
        role: null,
      });
    }

    setRows(rows);
  }, [roleToGroupMapping, roles]);

  React.useEffect(() => {
    setHasEmptyRows(rows.some(({ group, role }) => group === '' || role === null));
  }, [rows]);

  return (
    <ModalMd
      classes={'add-autoprovisioning-modal'}
      onHandleClose={handleToggle}
      open={isOpen}
      title={rows.length > 1 ? 'Edit Groups' : 'Add Groups'}
    >
      <div className="modal-body">
        {rows.map(({ group, role }, i) => (
          <React.Fragment key={i}>
            <div className="row-section">
              <InputTextbox
                classes={'group-textbox no-error'}
                label={'Group Name'}
                name={'group-textbox'}
                onChange={handleInputChange(i)}
                onFocus={handlePostUserInputMetric}
                placeholder={'Enter group name'}
                value={group}
              />
              <span className="arrow-icon" />
              <InputSelect
                classes={'group-selectbox'}
                label={'CyberCube Role'}
                objectsArr={roles}
                onUpdateSelectedObj={handleUpdateSelectedObj(i)}
                placeholder={'Select CyberCube Role'}
                selectedObj={role}
                disabled={roles.length === 0}
              />
              {rows.length > 1 && <span className="close-button" onClick={handleDeleteRow(i)} />}
            </div>
          </React.Fragment>
        ))}
        <div className="end-row">
          <div
            className={classnames('add-icon', { disabled: roles.length === 0 || hasEmptyRows })}
            onClick={handleAddRow}
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button color={'secondary'} onClick={handleToggle}>
          <FormattedMessage id="userModal.cancelButton" />
        </Button>
        <Button color={'primary'} disabled={hasEmptyRows} onClick={handleSubmit}>
          <FormattedMessage id="userModal.saveButton" />
        </Button>
      </div>
    </ModalMd>
  );
};

AddAutoprovisioningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  roleToGroupMapping: PropTypes.array,
  roles: PropTypes.array,
};

export default IntlFormatterHOC(AddAutoprovisioningModal);
