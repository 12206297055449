/* eslint-disable one-var */

import { Fetch } from 'c2-common-ui';

export const getAppDetails = () => Fetch.defaultNoAuth('common/getVersionDetails');
export const postClearUserMfa = payload => Fetch.post('admin/clearusermfa', payload);
export const postCreateUser = payload => Fetch.post('admin/createuser', payload);
export const postDisableUser = payload => Fetch.post('admin/deactivateuser', payload);
export const postEditUser = payload => Fetch.post('admin/edituser', payload);
export const postEditUserAccess = payload => Fetch.post('admin/edituseraccess', payload);
export const postEnableUser = payload => Fetch.post('admin/enableuser', payload);
export const postGetUserInfo = payload => Fetch.post('common/getuserinfo', payload);
export const postListUsers = signal => Fetch.post('admin/listusers', undefined, true, signal);
export const postResendInvite = payload => Fetch.post('admin/resendinvite', payload);
export const postResetPassword = payload => Fetch.post('common/resetpassword', payload);
export const postUnlockUser = payload => Fetch.post('admin/unlockuser', payload);
