/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import { Button } from 'c2-common-ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownMenu, DropdownToggle } from 'reactstrap';

const View = ({ email, isLoading, menuOpen, name, onCloseMenu, onLogoutUser, releaseDate, version }) => {
  const handleLogoutUser = () => {
    onLogoutUser();
    onCloseMenu();
  };

  if (isLoading) {
    return <div className="toggle-button" />;
  }

  return (
    <React.Fragment>
      <DropdownToggle className={classnames('toggle-button', { open: menuOpen })} />
      <DropdownMenu className="popup-menu">
        <div className="user-info">
          <div>{name}</div>
          <div>{email}</div>
          <div>user admin</div>
          <Button classes={'logout-button'} color={'secondary'} onClick={handleLogoutUser}>
            <FormattedMessage id="userAdmin.logoutButton" />
          </Button>
        </div>
        <div className="app-info">
          <div>About</div>
          <div>version {version}</div>
          <div>Release Date: {releaseDate}</div>
        </div>
      </DropdownMenu>
    </React.Fragment>
  );
};

View.propTypes = {
  email: PropTypes.string,
  isLoading: PropTypes.bool,
  menuOpen: PropTypes.bool,
  name: PropTypes.string,
  onCloseMenu: PropTypes.func,
  onLogoutUser: PropTypes.func,
  releaseDate: PropTypes.string,
  version: PropTypes.string,
};

export default View;
