import { createSlice } from '@reduxjs/toolkit';

import { createUser, editUser, fetchTenantInfo, fetchUsers } from './userAdmin.thunks';

const initialState = {
  addons: {},
  amActivations: 0,
  amLicenses: 0,
  amPmPendingInvites: 0,
  apiActivations: 0,
  apiLicenses: 0,
  bmActivations: 0,
  bmActiveUsers: 0,
  bmLicenses: 0,
  bmManagerActivations: 0,
  bmManagerLicenses: 0,
  bmPendingInvites: 0,
  loadingIndicators: [],
  pmActivations: 0,
  pmLicenses: 0,
  roles: [],
  searchInput: '',
  sessionUser: {},
  userGroups: [],
  users: [],
  xmActivations: 0,
  xmLicenses: 0,

  // Loading States
  createUserLoading: false,
  editUserLoading: false,
  fetchTenantInfoLoading: false,
  fetchUsersLoading: false,
};

export const userAdminSlice = createSlice({
  name: 'userAdmin',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    addLoadingIndicator: (state, action) => {
      state.loadingIndicators = [...state.loadingIndicators, action.payload];
    },
    deleteLoadingIndicator: (state, action) => {
      state.loadingIndicators = state.loadingIndicators.filter(element => element !== action.payload);
    },
    setAddons: (state, action) => {
      state.addons = action.payload;
    },
    setAmActivations: (state, action) => {
      state.amActivations = action.payload;
    },
    setAmLicenses: (state, action) => {
      state.amLicenses = action.payload;
    },
    setAmPmPendingInvites: (state, action) => {
      state.amPmPendingInvites = action.payload;
    },
    setApiActivations: (state, action) => {
      state.apiActivations = action.payload;
    },
    setApiLicenses: (state, action) => {
      state.apiLicenses = action.payload;
    },
    setBmActivations: (state, action) => {
      state.bmActivations = action.payload;
    },
    setBmActiveUsers: (state, action) => {
      state.bmActiveUsers = action.payload;
    },
    setBmLicenses: (state, action) => {
      state.bmLicenses = action.payload;
    },
    setBmManagerActivations: (state, action) => {
      state.bmManagerActivations = action.payload;
    },
    setBmManagerLicenses: (state, action) => {
      state.bmManagerLicenses = action.payload;
    },
    setBmPendingInvites: (state, action) => {
      state.bmPendingInvites = action.payload;
    },
    setCcActivations: (state, action) => {
      state.apiActivations = action.payload;
    },
    setCcLicenses: (state, action) => {
      state.apiLicenses = action.payload;
    },
    setPmActivations: (state, action) => {
      state.pmActivations = action.payload;
    },
    setPmLicenses: (state, action) => {
      state.pmLicenses = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
    setUserGroups: (state, action) => {
      state.userGroups = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setXmActivations: (state, action) => {
      state.xmActivations = action.payload;
    },
    setXmLicenses: (state, action) => {
      state.xmLicenses = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createUser.pending, state => {
        state.createUserLoading = true;
      })
      .addCase(createUser.fulfilled, state => {
        state.createUserLoading = false;
      })
      .addCase(editUser.pending, state => {
        state.editUserLoading = true;
      })
      .addCase(editUser.fulfilled, state => {
        state.editUserLoading = false;
      })
      .addCase(fetchTenantInfo.pending, state => {
        state.fetchTenantInfoLoading = true;
      })
      .addCase(fetchTenantInfo.fulfilled, state => {
        state.fetchTenantInfoLoading = false;
      })
      .addCase(fetchUsers.pending, state => {
        state.fetchUsersLoading = true;
      })
      .addCase(fetchUsers.fulfilled, state => {
        state.fetchUsersLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  addLoadingIndicator,
  deleteLoadingIndicator,
  roles,
  setAddons,
  setAmActivations,
  setAmLicenses,
  setAmPmPendingInvites,
  setApiActivations,
  setApiLicenses,
  setBmActivations,
  setBmActiveUsers,
  setBmLicenses,
  setBmManagerActivations,
  setBmManagerLicenses,
  setBmPendingInvites,
  setCcActivations,
  setCcLicenses,
  setPmActivations,
  setPmLicenses,
  setSearchInput,
  setUserGroups,
  setUsers,
  setXmActivations,
  setXmLicenses,
} = userAdminSlice.actions;

export const selectAmActivations = state => state.userAdmin.amActivations;
export const selectAmLicenses = state => state.userAdmin.amLicenses;
export const selectAmPmPendingInvites = state => state.userAdmin.amPmPendingInvites;
export const selectApiActivations = state => state.userAdmin.apiActivations;
export const selectApiLicenses = state => state.userAdmin.apiLicenses;
export const selectBmActivations = state => state.userAdmin.bmActivations;
export const selectBmActiveUsers = state => state.userAdmin.bmActiveUsers;
export const selectBmLicenses = state => state.userAdmin.bmLicenses;
export const selectBmManagerActivations = state => state.userAdmin.bmManagerActivations;
export const selectBmManagerLicenses = state => state.userAdmin.bmManagerLicenses;
export const selectBmPendingInvites = state => state.userAdmin.bmPendingInvites;
export const selectCreateUserLoading = state => state.userAdmin.createUserLoading;
export const selectEditUserLoading = state => state.userAdmin.editUserLoading;
export const selectFetchTenantInfoLoading = state => state.userAdmin.fetchTenantInfoLoading;
export const selectFetchUsersLoading = state => state.userAdmin.fetchUsersLoading;
export const selectLoadingIndicators = state => state.userAdmin.loadingIndicators;
export const selectPmActivations = state => state.userAdmin.pmActivations;
export const selectPmLicenses = state => state.userAdmin.pmLicenses;
export const selectUserGroups = state => state.userAdmin.userGroups;
export const selectUsers = state => state.userAdmin.users;
export const selectXmActivations = state => state.userAdmin.xmActivations;
export const selectXmLicenses = state => state.userAdmin.xmLicenses;

export default userAdminSlice.reducer;
