/* eslint-disable react/jsx-no-bind */
/* eslint-disable one-var */
import './styles.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'reactstrap';

import View from './view';

const SideNavUser = ({ classes, email, isLoading, name, onLogoutUser, releaseDate, version }) => {
  // Component States
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Dropdown
      className={classnames('side-nav-user', classes)}
      direction={'right'}
      isOpen={menuOpen}
      toggle={handleToggle}
    >
      <View
        email={email}
        isLoading={isLoading}
        menuOpen={menuOpen}
        name={name}
        onCloseMenu={handleCloseMenu}
        onLogoutUser={onLogoutUser}
        releaseDate={releaseDate}
        version={version}
      />
    </Dropdown>
  );
};

SideNavUser.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  email: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  onLogoutUser: PropTypes.func,
  releaseDate: PropTypes.string,
  version: PropTypes.string,
};

export default SideNavUser;
